import soledis from './global';

$('document').ready(function() {
  if (document.querySelectorAll('.sld-js-anchor').length > 0) {
    document.querySelectorAll('.sld-js-anchor').forEach(function(el) {
      el.addEventListener('click', function(e) {
        e.preventDefault();
        let anchor = this.getAttribute('href');
        let anchorPosition = $(anchor).offset();

        let stickyHeight = 150;
        if (typeof sld !== 'undefined' && typeof sld.headerHeight !== 'undefined') {
          stickyHeight  = sld.headerHeight + sld.stickyBottomHeight;
        }
        $('html, body').animate({scrollTop: anchorPosition.top - stickyHeight}, 1000);
      });
    });
  }
});

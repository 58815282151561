function updateDropdownPosition()
{
    var $amegamenu = $('#amegamenu');
    if ($amegamenu.length) {
        var updatePosition = function() {
            if ($amegamenu.hasClass('amegamenu_rtl')) {
                updateDropdownPositionRTL($amegamenu);
            }
            else {
                updateDropdownPositionLTR($amegamenu);
            }
        };

        updatePosition();
        $(window).resize(updatePosition);
    }
}

function updateDropdownPositionLTR(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent('.amenu-item'),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_left = p.offset().left,
        margin = parseInt(t.css('margin-left'));
          
    if ( t_right - margin + i > m_right) {
      t.css('margin-left', (m_right - p_left - t.outerWidth()) + 'px');
    } else if (t.offset().left - margin + i < m_left) {
      t.css('margin-left', (m_left - p_left) + 'px');
    } else {
      t.css('margin-left', i + 'px');
    }
  });
}
function updateDropdownPositionRTL(mm)
{
  var m_left = mm.offset().left,
      m_right = mm.offset().left + mm.outerWidth();

  $('.adropdown', mm).each(function() {
    let t = $(this),
        p = t.parent(),
        i = 0 - (t.outerWidth() - p.outerWidth())/2,
        t_right = t.offset().left + t.outerWidth(),
        p_right = p.offset().left + p.outerWidth(),
        margin = parseInt(t.css('margin-right'));
  
    if (t.offset().left + margin - i < m_left) {
      t.css('margin-right', (0 - (t.outerWidth() - p_right + m_left)) + 'px');
    } else if (t_right + margin - i > m_right) {
      t.css('margin-right', (0 - (m_right - p_right)) + 'px');
    } else {
      t.css('margin-right', i + 'px');
    }
  });
}

function mobileToggleEvent()
{
    
    $('#mobile-amegamenu .amenu-item.plex > .amenu-link').on('click', function() {
        if (!$(this).hasClass('expanded')) {
            $('#mobile-amegamenu .expanded').removeClass('expanded').next('.adropdown').slideUp();
        }
        $(this).next('.adropdown').stop().slideToggle();
        $(this).toggleClass('expanded');

        return false;
    });
}

function enableHoverMenuOnTablet()
{
    $('html').on('touchstart', function(e) {
        $('#amegamenu .amenu-item').removeClass('hover');
    });
    $('#amegamenu').on('touchstart', function (e) {
        e.stopPropagation();
    });
    $('#amegamenu .amenu-item.plex > .amenu-link').on('touchstart', function (e) {
        'use strict'; //satisfy code inspectors        
        var li = $(this).parent('li');
        if (li.hasClass('hover')) {
            return true;
        } else {
            $('#amegamenu .amenu-item').removeClass('hover');
            li.addClass('hover');
            e.preventDefault();
            return false;
        }
    });
}

function ajaxLoadDrodownContent() {
    var $ajaxmenu = $('.js-ajax-mega-menu');
    if ($ajaxmenu.length) {
        $.ajax({
            type: 'POST',
            url: $ajaxmenu.data('ajax-dropdown-controller'),
            data: {
                ajax: true,
            },
            dataType: 'json',
            success: function(data) {
                updateDrodownContent(data);
                sldDynamicSubMenu();
            },
            error: function(err) {
                console.log(err);
            }
        });

        var updateDrodownContent = function(dropdown) {
            $('.js-dropdown-content', $ajaxmenu).each(function () {
                let item = $(this).data('menu-id');
                $(this).replaceWith(dropdown[item]);
            });
        };

        
    }
}

$(window).load(function() {
    setTimeout(function() {
        ajaxLoadDrodownContent();
        updateDropdownPosition();

    }, 600);
    mobileToggleEvent();
    enableHoverMenuOnTablet();
});

function sldDynamicSubMenu() {
    // if menu category is dynamic
    if (document.querySelector('.sld-js-subcategory-dynamic') !== null) {
        let menu = document.querySelector('.acategory-content:not(.sld-js-subcategory-dynamic)');
        if (menu !== null) {
            menu.querySelectorAll('.sld-menu-category-link').forEach((link) => {
                sldHasSubMenu(link);
                menu.classList.add('sld-container-has-submenu');
            });
        }
        // if not mobile 
        if (typeof prestashop !== null && !prestashop.responsive.mobile) {
            document.querySelectorAll('.acategory-content a').forEach((link) => {
                link.addEventListener('mouseover', function() {
                    let idCategory = Number(link.getAttribute('data-category-id'));
                    if (typeof idCategory === 'number' && !isNaN(idCategory)) {
                        sldActiveSubMenu(idCategory);
                    }
                });
            });
        }
    }
}

function sldSubMenuLink(item) {
    // create button to link if the item has a sub menu
    if (!item.classList.contains('sld-sub-menu')) {
        item.classList.add('sld-sub-menu');
        let icon = document.createElement('span');
        icon.classList.add('material-icons');
        let textIcon = document.createTextNode('keyboard_arrow_right');
        icon.appendChild(textIcon);
        let link = document.createElement('button');
        link.classList.add('sld-sub-menu-link');
        link.appendChild(icon);
        if (typeof prestashop !== null && prestashop.responsive.mobile) {
            let idCategory = Number(item.getAttribute('data-category-id'));
            if (typeof idCategory === 'number' && !isNaN(idCategory)) {
                link.addEventListener('click', function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                    sldActiveSubMenu(idCategory);
                });
            }
        }
        item.appendChild(link);
    }
}

function sldHasSubMenu(link) {
    let idCategory = Number(link.getAttribute('data-category-id'));
    // if link has a id category
    if (typeof idCategory === 'number' && !isNaN(idCategory)) {
        let subMenu = document.querySelector('#subcategory-dynamic-'.concat(idCategory));
        // if link has a sub menu
        if (subMenu != null) {
            sldSubMenuLink(link);
            link.classList.add('sld-has-submenu');
        }
    }
}

function sldBackItem(subMenuActive) {
    // button back menu
    let backItem = subMenuActive.querySelector('.sld-js-back-item');
    if (backItem !== null) {
        subMenuActive.classList.add('sld-has-back-item');
        backItem.addEventListener('click', function(e) {
            e.preventDefault();
            backItem.parentNode.classList.remove('sld-sub-menu-active');
            let subMenuPrevContainer = backItem.parentNode.parentNode.previousElementSibling;
            if (subMenuPrevContainer !== undefined) {
                let subMenuPrev = subMenuPrevContainer.querySelector('.sld-sub-menu-active');
                if (subMenuPrev !== undefined) {
                    sldDropdownHeight(subMenuPrev);
                }
                else {
                    sldDropdownHeight();
                }
            }
        })
    }
}

function sldDropdownHeight(subMenuActive) {
    if (subMenuActive !== null) {
        let subMenuHeight = subMenuActive.offsetHeight;
        if (subMenuHeight !== null) {
            document.querySelector('.adropdown').style.height = subMenuHeight.toString().concat('px');
        }
    }
    else {
        document.querySelector('.adropdown').style.height = 'auto';
    }
}

function sldActiveSubMenu(idCategory) {
    itemActive = document.querySelector('[data-category-id="'+idCategory+'"]');
    subMenuActive = document.querySelector('#subcategory-dynamic-'.concat(idCategory));
    if (itemActive !== null) {
        itemOldActive = itemActive.parentNode.parentNode.parentNode.querySelector('.sld-menu-active');
        // remove class to the other old menu hover
        if (itemOldActive !== null) {
            itemOldActive.classList.remove('sld-menu-active');
        }
        // add class to the menu hover 
        itemActive.classList.add('sld-menu-active');
    }
    if (subMenuActive !== null) {
        if (!subMenuActive.classList.contains('sld-container-has-submenu')) {
            subMenuActive.querySelectorAll('.sld-menu-category-link').forEach((link) => {
                sldHasSubMenu(link);
                subMenuActive.classList.add('sld-container-has-submenu');
            });
        }
        if (typeof prestashop !== null && prestashop.responsive.mobile && !subMenuActive.classList.contains('sld-has-back-item')) {
            sldBackItem(subMenuActive);
        }
        // get container sub menu active
        subMenuOldActive = subMenuActive.parentNode.querySelector('.sld-sub-menu-active');
        // get next container sub menu active
        subMenuOldActive2 = subMenuActive.parentNode.nextElementSibling;
        // remove class to the other old container sub menu
        if (subMenuOldActive !== null) {
            sudItemOldActive = subMenuOldActive.querySelector('.sld-menu-active');
            // remove class to the other old menu hover in the old container
            if (sudItemOldActive !== null) {
                sudItemOldActive.classList.remove('sld-menu-active');
            }
            subMenuOldActive.classList.remove('sld-sub-menu-active');
        }
        // remove class to the next level 
        if (subMenuOldActive2 !== null) {
            subMenu2 = subMenuOldActive2.querySelector('.sld-sub-menu-active');
            if (subMenu2 !== null) {
                subMenu2.classList.remove('sld-sub-menu-active');
            }
        }
        // add class to the container sub menu
        subMenuActive.classList.add('sld-sub-menu-active');
        if (typeof prestashop !== null && prestashop.responsive.mobile) {
            // change height dropdown
            sldDropdownHeight(subMenuActive);
        }
    }
    else {
        // remove class in the next col if item are not sub-menu
        subMenuRemove = itemActive.parentNode.parentNode.parentNode.nextElementSibling;
        if (subMenuRemove !== null) {
            subMenuRemoveActive= subMenuRemove.querySelector('.sld-sub-menu-active')
            if (subMenuRemoveActive !==null) {
                subMenuRemoveActive.classList.remove('sld-sub-menu-active');
            }
        }
    }
}
import Toast from './components/toast/toast';
import Loader from './components/loader/loader';

export default class Soledis {
  constructor() {
    this.version = '1.0.0';
  }

  init() {
    // init toast
    const toast = new Toast();
    this.toast = toast;

    // init loader
    const loader = new Loader();
    this.loader = loader;
  }
}
